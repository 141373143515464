import React, { useEffect, useState } from "react";
import axios from "axios";
import configData from "../../../config.json";

const News = () => {
  const [dataset, setDataSet] = useState([]);
  const [nonFeature, setNonFeature] = useState([]);
  const [featureNews, setFeatureNews] = useState([]);
  const [inputDisable, setInputDisable] = useState(false);
  const [offset, setOffset] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [totalContent, setTotalContent] = useState([]);
  const limit = 6;

  function getDataSet() {
    axios.get(configData.API_URL + "news").then(function (response) {
      const feature = [];
      const nonfeature = [];
      const filteredData = response.data.data.filter(
        (item) => item.status === "ACTIVE"
      );
      filteredData.map((item) => {
        if (
          item.feature_new &&
          item.feature_new.toLowerCase() === "yes" &&
          feature.length < 2
        ) {
          feature.push(item);
        } else {
          nonfeature.push(item);
        }
      });
      setNonFeature([...nonfeature]);
      setTotalContent(filteredData);
      setFeatureNews(feature);
      if (nonfeature.length > limit) {
        setShowMore(true);
      }
      setDataSet([...nonfeature].slice(0, limit));
    });
  }
  const onChangeSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.length > 0) {
      setInputDisable(true);
      setShowMore(false);
    } else {
      setOffset(-1);
      setTimeout(() => {
        loadNews();
      }, 800);
    }
    if (e.target.value.length >= 3) {
      const filteredData = totalContent.filter((item) =>
        item.title.toLowerCase().includes(searchValue)
      );
      setDataSet(filteredData);
    }
  };
  const loadNews = () => {
    const offSet = offset + 1;
    setOffset(offSet);
    const newSet = [...nonFeature].slice(
      offSet * limit,
      offSet * limit + limit
    );
    setDataSet([...dataset, ...newSet]);
    setInputDisable(false);
    setShowMore(true);
  };
  useEffect(() => {
    getDataSet();
  }, []);

  return (
    <>
      <section className="fluid bg-light-gray-1">
        <div className="container">
          <div className="row pv-30">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <h2 className="mb-0 pdt-5 heading-2">Latest News</h2>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7">
              <form className="news-search-form" id="newsForm">
                <div className="fieldset">
                  <input
                    type="text"
                    name="newskey"
                    placeholder="Search"
                    onChange={onChangeSearch}
                  />
                  <i className="icon-magnifier" id="newsSearchBtn"></i>
                </div>
              </form>
            </div>
          </div>
          <div className="row pvb-30">
            <div className="col-xs-12 col-sm-12 col-md-12" id="newsData">
              {!inputDisable && (
                <div className="row  news-list-wrapper">
                  {featureNews.map((data, Index) => (
                    <div className="col-xs-12 col-sm-12 col-md-6 mb-30">
                      <a
                        href={`./news/${data.sku}`}
                        className="custom-news-list-overlap"
                        target="_blank"
                      >
                        <div className="img-wrapper">
                          <img
                            src={`${configData.IMAGE_BASE_URL}${data.thumbnail_image}`}
                            className="img-fluid"
                          />
                        </div>
                        <div className="custom-news-list-overlap-body">
                          <p className="news-list-title heading-3">
                            {data.title}
                          </p>
                          <p className="news-list-date">{data.news_date}</p>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              )}
              <div className="row news-list-wrapper">
                {dataset.map((data, Index) => (
                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 mb-30">
                    <a
                      href={`./news/${data.sku}`}
                      className="custom-news-list"
                      target="_blank"
                    >
                      <div className="img-wrapper">
                        <img
                          src={`${configData.IMAGE_BASE_URL}${data.thumbnail_image}`}
                          className="img-fluid"
                        />
                      </div>
                      <div className="custom-news-list-body">
                        <p className="news-list-title heading-3">
                          {data.title}
                        </p>
                        <p className="news-list-date">{data.news_date}</p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
              <div className="row news-list-wrapper">
                {inputDisable && dataset.length === 0 && (
                  <span>No Result Found</span>
                )}
              </div>
            </div>
          </div>
          {showMore && dataset.length < nonFeature.length && (
            <div className="row pvb-30">
              <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                <button type="button" className="load-more" onClick={loadNews}>
                  show more
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default News;
