import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import configData from "../../../config.json";
import { sortingASD } from "../../../util";
import { apiRequest } from "../../api/api";
import { gtag } from "ga-gtag";

const TestDriveForm = ({ backgroundImage, modelIndex = 0 }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cmscontents_1, setCmscontents_1] = useState([]);
  const [filteredDealerList, setFilteredDealerList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [locationList2, setLocationList2] = useState([]);
  const [allCityList, setAllCityList] = useState({});
  const [modelList, setModelList] = useState(["BYD-ATTO 3"]);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    model: "",
    state: "",
    city: "",
    location: "",
    dealer: "",
    dealercode: "",
    privacyPolicy: false,
    termCondition: false,
    dealer_email: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [dropDownState, setDropDownState] = useState("");
  const [dropDownStateToggle, setDropDownStateToggle] = useState(false);
  const [dropDownCity, setDropDownCity] = useState("");
  const [dropDownCityToggle, setDropDownCityToggle] = useState(false);
  const [dropDownDealer, setDropDownDealer] = useState("");
  const [dropDownDealerToggle, setDropDownDealerToggle] = useState(false);
  const [dropDownModel, setDropDownModel] = useState("");
  const [dropDownModelToggle, setDropDownModelToggle] = useState(false);
  const [dropDownLocation, setDropDownLocation] = useState("");
  const [dropDownLocationToggle, setDropDownLocationToggle] = useState(false);

  const stateRef = useRef();
  const cityRef = useRef();
  const locationRef = useRef();
  const dealerRef = useRef();
  const modelRef = useRef();

  const getMenuContent = () => {
    axios.get(configData.API_URL + "menu").then((response) => {
      const centerMenu = response.data.data.find(
        (item) => item.sku === "HEADER_CENTER_MENU"
      );
      if (centerMenu) {
        const mainMenu = JSON.parse(centerMenu.body);
        mainMenu.sort((a, b) => parseFloat(b.sortkey) - parseFloat(a.sortkey));
        const models = [];
        const index = modelIndex || 0;
        // const currentMenu = mainMenu.find(item => parseFloat(item.sortkey) === parseFloat(index));
        const currentMenu = mainMenu[0]; // remove

        if (currentMenu && currentMenu.hasChild) {
          currentMenu.child.forEach((elem) => {
            models.push(elem.label);
          });
        }
        setModelList(models);
      }
    });
  };

  const handleClick = (event) => {
    if (stateRef.current && !stateRef.current.contains(event.target)) {
      setDropDownStateToggle(false);
    }
    if (cityRef.current && !cityRef.current.contains(event.target)) {
      setDropDownCityToggle(false);
    }
    if (dealerRef.current && !dealerRef.current.contains(event.target)) {
      setDropDownDealerToggle(false);
    }
    if (modelRef.current && !modelRef.current.contains(event.target)) {
      setDropDownModelToggle(false);
    }
    if (locationRef.current && !locationRef.current.contains(event.target)) {
      setDropDownLocationToggle(false);
    }
  };
  const getCmscontents_1 = () => {
    axios.get(configData.API_URL + "dealers").then(function (response) {
      const data = response.data.data;
      data.sort(sortingASD);

      const state = [];
      const city = {};
      const location = {};

      data.forEach((item) => {
        if (!state.includes(item.state)) {
          state.push(item.state);
        }

        if (!city[item.state]) {
          city[item.state] = [];
        }
        if (!city[item.state].includes(item.city)) {
          city[item.state].push(item.city);
        }

        if (!location[item.city]) {
          location[item.city] = [];
        }
        if (!location[item.city].includes(item.location)) {
          location[item.city].push(item.location);
        }
      });
      setStateList(state);
      setAllCityList(city);
      setLocationList(location);
      setCmscontents_1(data);
    });
  };

  useEffect(() => {
    getMenuContent();
    getCmscontents_1();
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const toggleStateDropDown = () => {
    setDropDownStateToggle(!dropDownStateToggle);
  };
  const toggleCityDropDown = () => {
    setDropDownCityToggle(!dropDownCityToggle);
  };
  const toggleDealerDropDown = () => {
    setDropDownDealerToggle(!dropDownDealerToggle);
  };
  const toggleModelDropDown = () => {
    setDropDownModelToggle(!dropDownModelToggle);
  };

  const toggleLocationDropDown = () => {
    setDropDownLocationToggle(!dropDownLocationToggle);
  };

  const selectState = (item) => {
    toggleStateDropDown();
    setDropDownState(item);
    setCityList(allCityList[item]);
    setDropDownCity("");
    setDropDownLocation("");
    setDropDownDealer("");
    setIsFormSubmitted(false);
    const filterData = cmscontents_1.filter((itm) => itm.state === item);
    setFilteredDealerList(filterData);
    setFormData({
      ...formData,
      state: item,
      city: "",
      location: "",
      dealer: "",
    });
  };
  const selectCity = (item) => {
    toggleCityDropDown();
    setDropDownCity(item);
    setLocationList2(locationList[item]);
    setDropDownLocation("");
    setDropDownDealer("");
    setIsFormSubmitted(false);
    const filterData = cmscontents_1.filter((itm) => itm.city === item);
    setFilteredDealerList(filterData);
    setFormData({
      ...formData,
      city: item,
      location: "",
      dealer: "",
    });
  };

  const selectLocation = (item) => {
    // New function to select location
    toggleLocationDropDown();
    setDropDownLocation(item);
    setDropDownDealer("");
    setIsFormSubmitted(false);
    const filterData = cmscontents_1.filter((itm) => itm.location === item);
    setFilteredDealerList(filterData);
    setFormData({
      ...formData,
      location: item,
      dealer: "",
    });
  };

  const selectDealer = (item) => {
    toggleDealerDropDown();
    setDropDownDealer(item.name);
    setIsFormSubmitted(false);
    setFormData({
      ...formData,
      dealer: item.name,
      dealer_email: item.email,
      dealercode: item.dealercode,
    });
  };
  const selectModel = (item) => {
    toggleModelDropDown();
    setDropDownModel(item);
    setIsFormSubmitted(false);
    setFormData({
      ...formData,
      model: item,
    });
  };
  const handleCheckbox = (e) => {
    setIsFormSubmitted(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };
  const handleInputChange = (e) => {
    setIsFormSubmitted(false);
    const { name, value } = e.target;
    let newValue = value;

    switch (name) {
      case "name":
        newValue = value.replace(/[^a-zA-Z\s]/g, "");
        break;
      case "phone":
        newValue = value.replace(/[^0-9]/g, "");
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };
  const handleSubmit = async () => {
    const requiredFields = [
      "name",
      "phone",
      "email",
      "model",
      "state",
      "city",
      "location",
      "dealer",
      "dealercode",
      "privacyPolicy",
      "termCondition",
    ];

    const hasEmptyField = await requiredFields.some(
      (field) => !formData[field]
    );

    const isValidPhone = /^[0-9]{10}$/.test(formData.phone);
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);

    if (hasEmptyField || !isValidPhone || !isValidEmail) {
      setIsFormSubmitted(true);
      return;
    }

    setIsLoading(true);

    const modelMapping = {
      "byd emax 7": "16570127-00",
      "byd atto 3": "14673926-00",
      "byd sealion 7": "17808016-00",
      "byd seal": "15106090-00",
    };

    const modelCode = modelMapping[formData.model?.toLowerCase()];

    const payload = {
      name: formData.name,
      contact_number: formData.phone,
      email_id: formData.email,
      model_sku: formData.model,
      state: formData.state,
      city: formData.city,
      dealer: formData.dealer,
      dealercode: formData.dealercode,
      dealer_email: formData.dealer_email,
      model_code: modelCode,
      location: formData.location,
      source_reference: "source_reference",
      country: "India",
      source: "TELE-IN",
      token: false,
    };

    const headers = {
      Accept: "/",
      "Content-Type": "application/json",
    };

    axios
      .post(`${configData.API_URL}enquire-now`, payload, { headers })
      .then((response) => {
        if (dropDownModel === "BYD SEAL") {
          gtag("event", "BYD Seal Enquiry", {
            section_name: "Enquiry Form",
          });
        }
        setIsLoading(false);
        window.location.href = "/thankyou";
        navigate("/thankyou");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <section
        className="fluid test-drive-banner"
        style={{
          backgroundImage: `url(
            ${configData.IMAGE_BASE_URL}${backgroundImage}
          )`,
        }}
      >
        <div className="container">
          <div className="row mb-20 test-drive-form-wrapper">
            <form
              name="enquireNowForm"
              className="enquireNowForm"
              id="enquireNowForm"
            >
              <p className="title">
                Interested to know more about BYD vehicles
              </p>
              <p className="sub-title">
                Please share your contact details and we will connect with you.
              </p>

              <div className="fieldset mb-20">
                <input
                  type="text"
                  className="drive-input"
                  name="name"
                  id="drive_fullname"
                  placeholder="Name"
                  value={formData.name}
                  onChange={(e) => handleInputChange(e)}
                />
                {isFormSubmitted && !formData.name && (
                  <span className="red">Please enter Name</span>
                )}
              </div>
              <div className="fieldset mb-20">
                <input
                  type="tel"
                  className="drive-input"
                  name="phone"
                  id="drive_contact"
                  placeholder="Contact No."
                  value={formData.phone}
                  maxLength={10}
                  onChange={(e) => handleInputChange(e)}
                />
                {isFormSubmitted && !formData.phone && (
                  <span className="red validation-error">
                    Please enter valide Contact No.
                  </span>
                )}
              </div>
              <div className="fieldset mb-20">
                <input
                  type="email"
                  className="drive-input"
                  name="email"
                  id="drive_email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) => handleInputChange(e)}
                />
                {isFormSubmitted && !formData.email && (
                  <span className="red">Please enter Email</span>
                )}
              </div>
              <div className="fieldset mb-20">
                <div className="customDropDown fluid" ref={modelRef}>
                  <div className="dropDownLabel" onClick={toggleModelDropDown}>
                    <span>{dropDownModel || "Model"}</span>
                    <i className="icon-arrow-down"></i>
                  </div>
                  <ul className={dropDownModelToggle ? "active" : ""}>
                    {modelList.map((item, index) => (
                      <li
                        key={`model_${index}`}
                        data-index={index}
                        onClick={() => selectModel(item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {isFormSubmitted && !formData.model && (
                  <span className="red">Please select Model</span>
                )}
              </div>
              <div className="fieldset mb-20">
                <div className="customDropDown fluid" ref={stateRef}>
                  <div className="dropDownLabel" onClick={toggleStateDropDown}>
                    <span>{dropDownState || "State"}</span>
                    <i className="icon-arrow-down"></i>
                  </div>
                  <ul className={dropDownStateToggle ? "active" : ""}>
                    {stateList.map((item, index) => (
                      <li
                        key={`state_${index}`}
                        data-index={index}
                        onClick={() => selectState(item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {isFormSubmitted && !formData.state && (
                  <span className="red">Please select State</span>
                )}
              </div>
              <div className="fieldset mb-20">
                <div className="customDropDown fluid" ref={cityRef}>
                  <div className="dropDownLabel" onClick={toggleCityDropDown}>
                    <span>{dropDownCity || "City"}</span>
                    <i className="icon-arrow-down"></i>
                  </div>
                  <ul className={dropDownCityToggle ? "active" : ""}>
                    {cityList.map((item, index) => (
                      <li
                        key={`city_${index}`}
                        data-index={index}
                        onClick={() => selectCity(item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {isFormSubmitted && !formData.city && (
                  <span className="red">Please select City</span>
                )}
              </div>
              <div className="fieldset mb-20">
                <div className="customDropDown fluid" ref={locationRef}>
                  <div
                    className="dropDownLabel"
                    onClick={toggleLocationDropDown}
                  >
                    <span>{dropDownLocation || "Location"}</span>
                    <i className="icon-arrow-down"></i>
                  </div>
                  <ul className={dropDownLocationToggle ? "active" : ""}>
                    {locationList2.length > 0 &&
                      locationList2?.map((item, index) => (
                        <li
                          key={`location_${index}`}
                          data-index={index}
                          onClick={() => selectLocation(item)}
                        >
                          {item}
                        </li>
                      ))}
                  </ul>
                </div>
                {isFormSubmitted && !formData.location && (
                  <span className="red">Please enter Location</span>
                )}
              </div>
              <div className="fieldset mb-20">
                <div className="customDropDown fluid" ref={dealerRef}>
                  <div className="dropDownLabel" onClick={toggleDealerDropDown}>
                    <span>{dropDownDealer || "Dealer"}</span>
                    <i className="icon-arrow-down"></i>
                  </div>
                  <ul className={dropDownDealerToggle ? "active" : ""}>
                    {filteredDealerList.map((item, index) => (
                      <li
                        key={`dealer_${index}`}
                        data-index={index}
                        onClick={() => selectDealer(item)}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
                {isFormSubmitted && !formData.dealer && (
                  <span className="red">Please select Dealer</span>
                )}
              </div>
              <div className="fieldset">
                <label>
                  <input
                    type="checkbox"
                    className="drive-input"
                    name="privacyPolicy"
                    id="privacypolicy"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <p className="clicking">
                    I have read the{" "}
                    <a
                      className="underline-link load-moretd"
                      href="javascript:window.open('/privacy-policy','mypopuptitle','width=600,height=400')"
                    >
                      Privacy Policy
                    </a>{" "}
                    before clicking the 'Send' button below.
                  </p>
                </label>
                {isFormSubmitted && !formData.privacyPolicy && (
                  <span className="red">Please select Privacy Policy</span>
                )}

                <label>
                  <input
                    type="checkbox"
                    className="drive-input"
                    name="termCondition"
                    id="term_condition"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <p className="clicking">
                    I agree to the{" "}
                    <a
                      className="underline-link load-moretd"
                      href="javascript:window.open('/terms-conditions','mypopuptitle','width=600,height=400')"
                    >
                      Terms & Conditions
                    </a>{" "}
                    by clicking the 'Send' button below.
                  </p>
                </label>
                {isFormSubmitted && !formData.termCondition && (
                  <span className="red">Please select Terms & Conditions</span>
                )}
              </div>
              <button type="button" onClick={handleSubmit}>
                {!isLoading ? "SEND" : "SENDING..."}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestDriveForm;
