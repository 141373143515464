import React, { useState } from 'react';
import configData from '../../../config.json';

const BydAttoSlider = () => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedColor, setSelectedColor] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);

    const slides = [
        { image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1740461378524.png`, backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1720253597520.webp`, text: 'COSMOS BLACK' },
        { image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1740461617085.png`, backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1720251702947.webp`, text: 'SKI WHITE' },
        { image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1740461504568.png`, backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1720251925809.webp`, text: 'BOULDER GREY' },
        { image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1740134453254.png`, backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1720249755449.webp`, text: 'SURF BLUE' },
    ];    

    const colors = ['#1e2024', '#e9e9ea', '#5e636d', '#155d7f'];


    const changeSlide = (index) => {
        setCurrentSlide(index);
        setActiveIndex(index);
        setSelectedColor(index);

    };
    return (
        <>         
            <section className="fluid colorSection">
                <div className="slider-container">
                    <div className="slider">
                        {slides.map((slide, index) => (
                            <div
                                key={index}
                                className={`slide ${activeIndex === index ? 'active' : ''}`}
                                style={{ backgroundImage: `url(${slide.backgroundImage})` }}
                            >
                                <div className="text">{slide.text}</div>
                                <img
                                    src={slide.image} alt={`Slide ${index + 1}`} className="slide-image atto3-slider" />

                            </div>
                        ))}
                    </div>
                </div>
                <div className="input-container">
                    {colors.map((color, index) => (
                        <div key={index} className="color-button" style={{ backgroundColor: color, border: index === selectedColor ? '2px solid black' : 'none' }} onClick={() => changeSlide(index)}></div>
                    ))}
                </div>
            </section>
        </>
    )
}

export default BydAttoSlider