import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import configData from "../../../config.json";
import { sortingASD } from "../../../util";

const Finddealer = () => {
  const [cmscontents_1, setCmscontents_1] = useState([]);
  const [filteredDealerList, setFilteredDealerList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [allCityList, setAllCityList] = useState({});
  const [allLocationList, setAllLocationList] = useState({});

  const [dropDownState, setDropDownState] = useState("State");
  const [dropDownStateToggle, setDropDownStateToggle] = useState(false);
  const [dropDownCity, setDropDownCity] = useState("City");
  const [dropDownCityToggle, setDropDownCityToggle] = useState(false);
  const [dropDownLocation, setDropDownLocation] = useState("Location");
  const [dropDownLocationToggle, setDropDownLocationToggle] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const stateRef = useRef();
  const cityRef = useRef();
  const locationRef = useRef();

  useEffect(() => {
    getCmscontents_1();
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleClick = (event) => {
    if (stateRef.current && !stateRef.current.contains(event.target)) {
      setDropDownStateToggle(false);
    }
    if (cityRef.current && !cityRef.current.contains(event.target)) {
      setDropDownCityToggle(false);
    }
    if (locationRef.current && !locationRef.current.contains(event.target)) {
      setDropDownLocationToggle(false);
    }
  };

  const getCmscontents_1 = () => {
    axios.get(configData.API_URL + "dealers").then((response) => {
      const data = response.data.data.filter(
        (item) => item.status === "ACTIVE"
      );
      data.sort(sortingASD);

      const state = [];
      const city = {};
      const location = {};

      data.forEach((item) => {
        if (!state.includes(item.state)) state.push(item.state);
        if (!city[item.state]) city[item.state] = [];
        if (!city[item.state].includes(item.city))
          city[item.state].push(item.city);

        if (!location[item.city]) location[item.city] = [];
        if (!location[item.city].includes(item.location))
          location[item.city].push(item.location);
      });

      setStateList(state);
      setAllCityList(city);
      setAllLocationList(location);
      setCmscontents_1(data);
      setFilteredDealerList(data);
    });
  };

  const toggleStateDropDown = () =>
    setDropDownStateToggle(!dropDownStateToggle);
  const toggleCityDropDown = () => setDropDownCityToggle(!dropDownCityToggle);
  const toggleLocationDropDown = () =>
    setDropDownLocationToggle(!dropDownLocationToggle);

  const selectState = (item) => {
    toggleStateDropDown();
    setDropDownState(item);
    setResetFilter(true);
    setDropDownCity("City");
    setCityList(allCityList[item] || []);
    setDropDownLocation("Location");
    setLocationList([]);
    setFilteredDealerList(cmscontents_1.filter((itm) => itm.state === item));
  };

  const selectCity = (item) => {
    toggleCityDropDown();
    setDropDownCity(item);
    setDropDownLocation("Location");
    setLocationList(allLocationList[item] || []);
    setFilteredDealerList(cmscontents_1.filter((itm) => itm.city === item));
  };

  const selectLocation = (item) => {
    toggleLocationDropDown();
    setDropDownLocation(item);
    setFilteredDealerList(cmscontents_1.filter((itm) => itm.location === item));
  };

  const resetFilterMethod = () => {
    setResetFilter(false);
    setDropDownState("State");
    setDropDownCity("City");
    setDropDownLocation("Location");
    setCityList([]);
    setLocationList([]);
    setFilteredDealerList([...cmscontents_1]);
  };

  return (
    <section className="fluid background-white">
      <div className="container">
        <div className="row pv-30">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-30 text-center">
            <div className="filterWrapper">
              <div className="customDropDown" ref={stateRef}>
                <div className="dropDownLabel" onClick={toggleStateDropDown}>
                  <span>{dropDownState}</span>
                  <i className="icon-arrow-down"></i>
                </div>
                <ul className={dropDownStateToggle ? "active" : ""}>
                  {stateList.map((item, index) => (
                    <li key={index} onClick={() => selectState(item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="customDropDown" ref={cityRef}>
                <div className="dropDownLabel" onClick={toggleCityDropDown}>
                  <span>{dropDownCity}</span>
                  <i className="icon-arrow-down"></i>
                </div>
                <ul className={dropDownCityToggle ? "active" : ""}>
                  {cityList.map((item, index) => (
                    <li key={index} onClick={() => selectCity(item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="customDropDown" ref={locationRef}>
                <div className="dropDownLabel" onClick={toggleLocationDropDown}>
                  <span>{dropDownLocation}</span>
                  <i className="icon-arrow-down"></i>
                </div>
                <ul className={dropDownLocationToggle ? "active" : ""}>
                  {locationList.map((item, index) => (
                    <li key={index} onClick={() => selectLocation(item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              {resetFilter && (
                <button
                  className="resetButton"
                  type="button"
                  onClick={resetFilterMethod}
                >
                  <i className="icon-close"></i>
                </button>
              )}
            </div>
          </div>

          {filteredDealerList.map((item, index) => (
            <div
              key={index}
              className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-30"
            >
              <div className="dealer-card">
                <div className="dealer-card-image">
                  <img
                    src={`${configData.IMAGE_BASE_URL}${item.thumbnail_image}`}
                    className="img-fluid"
                    alt={item.name}
                  />
                </div>
                <div className="dealer-card-body">
                  <p className="mb-0 heading-4 text-capitalize">{item.state}</p>
                  <p className="mb-0 heading-3">{item.name}</p>
                  <p className="mb-0">{item.address}</p>
                  <p>
                    <i className="icon-phone"></i> {item.phone_no}
                  </p>
                  {item.alternative_phoneno && (
                    <p>
                      <i className="icon-phone"></i> {item.alternative_phoneno}
                    </p>
                  )}
                  <p>
                    <i className="icon-envelope"></i> {item.email}
                  </p>
                  {item.alternative_email && (
                    <p>
                      <i className="icon-envelope"></i> {item.alternative_email}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Finddealer;
